@import '/src/styles/generic/global/font.scss';
@import '/src/styles/settings/colors';

.wrapper {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: unset;
}
.sticky_pos {
    position: sticky;
    background-color: $color-white;
    z-index: 4;
    border-bottom: 1px solid $color-light-gray-input;
}
.fixed_pos {
    position: fixed;
    background-color: $color-white;
    z-index: 2;
    border-bottom: 1px solid $color-light-gray-input;
}
.band {
    position: relative;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 88px;
}
.abs {
    position: absolute;
}
.abs_lyp {
    background-color: $color-white;
}
.band_sticky_hp {
    transform: translateY(-100px);
    position: fixed;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 8px 16px #00000029;
    border-bottom: 1px solid $color-light-gray-input;
    background-color: $color-white;
}
.band_sticky_hp_anim {
    transform: translateY(0);
    transition: all 0.3s ease;
}
.band_sticky_normal {
    transform: none;
}

.hp {
    .main {
        max-width: 1920px;
        padding: 0 100px;
        @media screen and (max-width: 1136px) {
            padding: 0 30px;
        }
    }
}
.sr {
    z-index: unset;
    .main {
        padding: unset;
        @media screen and (max-width: 1099px) {
            padding: 0 20px;
        }
    }
    .nav_black {
        @media screen and (max-width: 1287px) {
        .nav_item_title {
            display: none !important;
        }
    }
    @media screen and (min-width: 1288px) {
        .nav_item_symb {
            display: none !important;
        }
    }
    }
}


.main {
    margin: 0 auto;
    // max-width: 1920px;
    padding: 0 50px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1136px) {
        padding: 0 20px;
    }
    @media screen and (min-width: 1440px) {
        padding: 0 calc((100vw - 1340px) / 2);
    }
    @media screen and (max-width: 800px) {
        // .nav_item_title {
        //     display: none;
        // }
        .nav_item_tooltip {
            display: unset;
        }
    }
}
.ldpmain{
    @media screen and (min-width: 1440px) {
        padding: 0 calc((100vw - 1074px) / 2);
    }
    @media (min-width: 1681px) {
        padding: 0 calc( (100vw - 1340px) / 2);
    }
}
.main.main_lyp {
    // background-color: $color-white;
    padding-left: 0;
    border-bottom: 1px solid #e9e9eb;
    .logo {
        background-color: $color-light-gray-input;
        height: 88px;
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 1136px) {
        .logo {
            // height: 88px;
            width: 64px;
            svg {
                transform: scale(0.7);
            }
        }
    }
}

.logo {
    flex: 0 0 auto;
}
.logo_white {
    path {
        fill: white;
    }
}


.nav {
    flex: 0 0 auto;
}
.nav_inner {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav_item {
    padding: 0 13px;
    position: relative;
    // z-index: 3;
    cursor: pointer;
}
.nav_item > a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav_item_title {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.01em;
    color: $color-white;
    white-space: pre;
}
.nav_item::after {
    position: absolute;
    // width: calc(100% + 24px);
    width: 100%;
    height: calc(100% + 10px);
    top: -5px;
    left: 0;
    // left: -12px;
    content: "";
    background: rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease;
}
.nav_item:hover::after {
    opacity: 1;
}
.nav_item:hover .nav_item_tooltip {
    top: 30px;
    opacity: 1;
}

.nav_item_symb {
    height: 14px;
    width: 10px;
    // margin-right: 8px;
    position: relative;
    padding: 0 0 2px 2px;
}
.nav_item_symb > svg {
    top: -5px;
    left: -8px;
    position: absolute;
}
.nav_item_symb_help  {
    top: -2px !important;
}
.nav_item_symb > svg path,
.nav_item_symb > svg circle,
.nav_item_symb > svg rect {
    stroke: $color-white;
}
.nav_item_symb_help path {
    fill: $color-white;
}
.nav_item_tooltip {
    display: none;
    position: absolute;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    background: $color-white;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.nav_item_tooltip::before {
    position: absolute;
    left: 50%;
    top: -7px;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid $color-white;
    transform: translateX(-50%);
}
.nav_item_tooltip_text {
    font-size: 10px;
    line-height: 1;
    color: $color-gray-hp;
    padding: 5px 10px;
    text-align: center;
    cursor: default;
}

// .band_sticky {
.nav_black {
    .nav_item_title {
        color: $color-black;
    }
    .nav_item:hover {
        .nav_item_title {
            color: $color-white;
        }
        .nav_item_symb > svg path,
        .nav_item_symb > svg circle,
        .nav_item_symb > svg rect {
            stroke: $color-white;
        }
        .nav_item_symb_help path {
            fill: $color-white;
        }
       
    }
    .nav_item:hover::after {
        background: $color-black;
    }
    .nav_item_symb > svg path,
    .nav_item_symb > svg circle,
    .nav_item_symb > svg rect {
        stroke: $color-black;
    }
    .nav_item_symb_help path {
        fill: $color-black;
    }
    .nav_item_tooltip {
        background: $color-black;
    }
    .nav_item_tooltip::before {
        border-bottom: 8px solid $color-black;
    }
    .nav_item_tooltip_text {
        color: $color-white;
    }
}




.nav_currency {
    padding: 0 12px;
}

.auth_btn_wrapper {
    margin-left: 12px;
    position: relative;
    display: flex;
}
.auth_btn_extra {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}
.auth_btn {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
}
.auth_open {
    overflow: hidden;
}
.user_drop_down_wrapper {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.user_drop_down {
    border-radius: 15px;
    z-index: 11;
    display: none;
    position: absolute;
    right: 0;
    top: 2.5rem;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.user_drop_down_open {
    display: block;
}

.hide.hide.hide.hide {
    display: none;
}