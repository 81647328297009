@import '/src/styles/settings/colors';
@import '/src/styles/generic/global/font.scss';

.wrapper {
    font-size: 1rem;
    font-family: inherit;
    z-index: 105;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
// .mobile_overlay,
.desktop_overlay {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.desktop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0,-100vh);
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
}
.desktop_visible {
    transform: translate(0, 0);
}
.desktop_inner {
    position: relative;
    max-width: 414px;
    // max-height: 96vh;
    background-color: $color-white;
    // border-radius: 10px;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}
.desktop_header {
    padding: 40px;
    flex: 0 0 auto;
}
.desktop_header_title {
    text-align: center;
    font-size: 23px;
    line-height: 27px;
    font-weight: 600;
}
.desktop_header_close {
    position: absolute;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3px;
    right: 3px;
}
.desktop_header_close:hover {
    cursor: pointer;
}

.desktop_content {
    padding: 0 40px 40px 40px;
    flex: 1 1 auto;
    overflow-y: auto;
}

.mobile {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light-gray-shade;
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    transform: translate(-100vw,0);
}
.mobile_visible {
    transform: translate(0, 0);
}

.mobile_inner {
    position: relative;
    z-index: 250;
    width: 100%;
    height: 100%;
    // max-height: 96vh;
    display: flex;
    flex-direction: column;
}
.mobile_header {
    width: 100%;
    padding: 28px 20px;
    background-color: $color-white;
}
.mobile_header_title {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}
.mobile_header_close {
    position: absolute;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25px;
    left: 13px;
}
.mobile_content {
    padding: 20px;
    overflow-y: auto;
}



.auth_title {
    text-align: center;
    margin-bottom: 20px;
}

.auth_form_ul {
    list-style-type: none;
}
.auth_form_item {
    min-width: 334px;
    margin-bottom: 20px;
    position: relative;
    margin-top: 15px;
}
div.auth_form_item__phone_container.auth_form_item__phone_container {
    width: 100%;
}
div.auth_form_item__phone_container.auth_form_item__phone_container::before {
    content: "";
    pointer-events: none;
    width: 17px;
    height: 11px;
    background-color: $color-field-grey;
    position: absolute;
    z-index: 5;
    top: 18px;
    left: 28px;
}
div.auth_form_item__phone_container.auth_form_item__phone_container.auth_form_item__phone_container_selected::before {
    z-index: 0;
}

.auth_form_item__symbol {
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 9px;
    right: 8px;
}
.auth_form_item__symbol:hover {
    cursor: pointer;
}
.auth_form_submit.auth_form_submit.auth_form_submit {
    color: $color-white;
    min-width: 334px;
    width: 100%;
}
.auth_form_submit2 {
    color: $color-white;
    width: 276px;
}
.auth_form_input {
    width: 100%;
    height: auto;
    padding: 10px 15px;
    border-radius: 100px;
    border: 1px solid $color-field-grey;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    font-family: $primary-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}
.auth_form_input_password {
    padding-right: 44px;
}

.auth_form_error {
    padding: 0 8px;
    margin-top: 10px;
}
.auth_form_error_inner {
    padding: 6px 16px;
    border-radius: 8px;
    display: flex;
    background-color: $color-pale-pink;
    color: #DA1414;
}
.auth_form_error_svg {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth_form_error_text {
    flex: 1;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
}

.login_refto_signup {
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: $color-dark-grey;
}
.login_refto_signup_a {
    margin-left: 4px;
    font-weight: 600;
}
.login_refto_signup_a:hover {
    cursor: pointer;
}

.login_more_otions {
    position: relative;
    margin-top: 30px;
    margin-bottom: 10px;
}
.login_more_otions__line {
    position: absolute;
    width: 100%;
    border-top: 1px solid #DCDADC;
}
.login_more_otions__text {
    font-size: 14px;
    font-weight: 400;
    color: $color-subtle-grey;
    display: flex;
    justify-content: center;
}
.login_more_otions__text_span_mobile,
.login_more_otions__text_span_desktop {
    position: relative;
    top: -11px;
    padding: 0 30px;
}
.login_more_otions__text_span_desktop {
    background-color: $color-white;
}
.login_more_otions__text_span_mobile {
    background-color: $color-white;
}

div.loading {
    height: 24px;
}


.auth_login_options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 12px;
}
.auth_signup_options {
    display: grid;
    grid-template-columns: 334px;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 8px;
    @media screen  and (max-width: 600px) {
        grid-template-columns: none;
    }
}
.auth_login_options_item {
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    border: 1px solid $color-field-grey;
    border-radius: 30px;
}
.auth_login_options_item:hover {
    cursor: pointer;
    border: 1px solid $color-subtle-grey;
}
.auth_login_options_item_inner {
    width: 114px;
    display: flex;
    // justify-content: center;
    align-items: center;
}
.auth_login_options_item_hide {
    display: none;
}
.auth_login_options_item__text {
    font-size: 14px;
    color: $color-subtle-grey;
    margin-left: 20px;
}

.save_big {
    padding: 20px;
    background-color: $color-white;
    border-radius: 0.5px solid $color-field-grey;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    border-radius: 15px;
    margin-bottom: 20px;
}
.save_big_top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.save_big_top_symbol {
    flex: 0 0 auto;
}
.save_big_top_text {
    flex: 1;
    margin-left: 15px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
}
.save_big_middle {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
}
.save_big_bottom {
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: $color-dark-grey;
}
.save_big_bottom_a {
    margin-left: 4px;
    font-weight: 600;
}

.complete_profile_symb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.complete_profile_text {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
}
.complete_profile_skip {
    margin-top: 40px;
    text-align: center;
}
.complete_profile_skip:hover {
    cursor: default;
}
.complete_profile_skip_inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
}
.complete_profile_skip_inner:hover {
    text-decoration-line: underline;
    cursor: pointer;
}

.phone_verification_subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 40px;
    color: $color-black;
}
.phone_verification_text {
    display: flex;
    justify-content: center;
}
.phone_verification_text_inner {
    max-width: 240px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 40px;
}
.phone_verification_timer {
    text-align: center;
    margin-top: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}
.phone_verification_resend {
    margin-top: 40px;
    text-align: center;
    @media (max-width: 600px) {
       margin-top: unset;
    }
}
.phone_verification_resend:hover {
    cursor: default;
}
.phone_verification_resend_inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
}
.phone_verification_resend_inner_wait {
    color: grey;
    cursor: not-allowed !important;
}
.phone_verification_resend_inner:hover {
    text-decoration-line: underline;
    cursor: pointer;
}

.phone_verification_code {
    margin-bottom: 40px;
}
.phone_verification_form {
    display: flex;
    justify-content: center;
}
.phone_verification_form_ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 58px);
    column-gap: 20px;
}
.phone_verification_form_item {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.phone_verification_form_input {
    border: 1px solid $color-subtle-grey;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    font-family: $primary-font;
    font-size: 26px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    color: $color-dark-grey;
}
.phone_verification_form_input_black {
    border: 1px solid $color-pure-black;
}
.phone_verification_form_input_red {
    border: 1px solid $color-error-red;
}
.phone_verification_form_input::placeholder {
    color: $color-light-gray-shade;
}
input.phone_verification_form_input::-webkit-outer-spin-button,
input.phone_verification_form_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.phone_verification_form_input[type=number] {
  -moz-appearance: textfield;
}

.auth_checkbox {
    display: flex;
    justify-content: flex-end;
}
.auth_checkbox_box.auth_checkbox_box {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    column-gap: 10px;
}
.auth_checkbox_mark.auth_checkbox_mark.auth_checkbox_mark {
    grid-column: 2;
    grid-row: 1;
    width: 14px;
    height: 14px;
    min-width: 0;
    border-radius: 4px;
    border: none;
}
.auth_checkbox_text {
    grid-column: 1;
    grid-row: 1;
}

.separator {
    height: 0;
    width: 100%;
    border-top: 1px solid $color-light-gray-input;
    margin: 20px 0;
}

.forgot_link {
    text-align: center;
    height: 20px;
}
.forgot_link:hover {
    cursor: default;
}
.forgot_link_inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
}
.forgot_link_inner:hover {
    text-decoration-line: underline;
    cursor: pointer;
}

.disclaimer {
    width: 100%;
    display: flex;
    justify-content: center;
}
.disclaimer_inner {
    max-width: 324px;
    white-space: pre-line;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    font-weight: 400;
}
.auth_form_item_otploginor {
    color: $color-vibrant-pink;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; 
    letter-spacing: 0.16px;
    text-align: center;
    margin-top: 10px;
}
.auth_form_item_otplogin {
    border-radius: 30px;
    border: 1px solid $color-primary;
    width: 100%;
    color: $color-primary;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
    letter-spacing: 0.16px;
    margin-top: 18px;
    height: 46px;
    place-content: center;
    cursor: pointer;
}
.auth_form_item_otpdisabled {
    border: 1px solid $color-dark-grey;
    color: $color-dark-grey;
}
.auth_form_item_disableotpcursor {
    pointer-events: none;
}
.tooltipPassword {

    max-width: 292px !important;
    @media (max-width: 600px) {
        max-width: 50%;
        min-width: 50px;
    }

    line-height: 18px;
    color: $color-shade-gray !important;
    border-radius: 17px !important;
    padding: 12px 18px 10px 20px !important;
    font-size: 12px !important;
    opacity: 1 !important;
}
.recaptcha {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
